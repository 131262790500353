import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { ClearCache, CacheKey, Cache } from 'src/app/core/services/cache/cache.service';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { map } from 'rxjs/operators';
import { TypeCahierCode } from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import { TypeChangementProduitCode } from '../../models/enums/type-changement-produit-code.enum';

@Injectable({
  providedIn: 'root'
})
export class ProduitsService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
  ) { }

  @ClearCache
  clearCache() { }


  @Cache()
  getProduits(@CacheKey idOperateur: number, @CacheKey estBacchus: boolean, @CacheKey typeCahierCode?: TypeCahierCode): Observable<Produit[]> {
    const params = [];
    if (typeCahierCode !== undefined) {
      params.push('typeCahier=' + typeCahierCode);
    }
    if (estBacchus !== undefined) {
      params.push('estBacchus=' + estBacchus);
    }

    const paramsStr = params.length ? '?' + params.join('&') : '';

    return this.http.get<object[]>(`/api/declaration/private/operateurs/${idOperateur}/produits${paramsStr}`).pipe(
      map(produits => this.mapper.deserializeArray(produits, Produit))
    );
  }

  getProduitsByProduitOrigine(idRefProduit: number): Observable<Produit[]> {

    return this.http.get<object[]>(`/api/declaration/private/produits/produit-origine/${idRefProduit}`).pipe(
      map(produits => this.mapper.deserializeArray(produits, Produit))
    );
  }

  getProduitsByProduitOrigineAndTypeChangement(idRefProduit: number, typeChangement: string ): Observable<Produit[]> {

    return this.http.get<object[]>(`/api/declaration/private/produits/produit-origine/${idRefProduit}/type/${typeChangement}`).pipe(
      map(produits => this.mapper.deserializeArray(produits, Produit))
    );
  }

  getProduitsById(idRefProduits: number[]): Observable<Produit[]> {

    return this.http.post<object[]>(`/api/declaration/private/produits`,idRefProduits).pipe(
      map(produits => this.mapper.deserializeArray(produits, Produit))
    );
  }

}
