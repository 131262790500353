import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router, Routes } from "@angular/router";
import { SuiviInventaireVergerModel } from "src/app/data/suivis/models/suivi-inventaire-verger.model";
import { SuiviInventaireVergerService } from "src/app/data/suivis/services/suivi-inventaire-verger/suivi-inventaire-verger.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChoixColonnesComponent } from "../../../../../shared/components/choix-colonnes/choix-colonnes.component"; // TODO : déplacer / mettre en commun
import { forkJoin, from, of } from "rxjs";
import { RefSousTypesService } from "src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service";
import { Structure } from "src/app/data/intervenant/models/structure.model";
import * as moment from "moment";
import { ExportToCsv } from "export-to-csv";
import { RefSoustypeUtilisateur } from "src/app/data/droits/models/ref-soustype-utilisateur.model";
import { Utilisateur } from "src/app/data/intervenant/models/utilisateur.model";
import { AdvBootstrapModalService } from "@adv/bootstrap-modal";
import { UniteCulturaleService } from "src/app/data/declaration/services/parcellaire/unite-culturale.service";
import { UniteCulturale } from "src/app/data/declaration/models/unite-culturale.model";
import { RefCampagnesService } from "src/app/data/declaration/services/ref-campagnes/ref-campagnes.service";
import { Campagne } from "src/app/data/declaration/models/campagne.model";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";
import { SessionContext } from "src/app/core/services/config/app.settings";
import { EntrepriseReferent } from "src/app/data/intervenant/models/entreprise-referent.model";
import { EntreprisesService } from "src/app/data/intervenant/services/entreprises/entreprises.service";
import { ContactReferent } from "src/app/data/intervenant/models/contact-referent.model";
import { FicheEntrepriseComponent } from "../../../administration/pages/entreprises/pages/fiche-entreprise/fiche-entreprise/fiche-entreprise.component";
import { Entreprise } from "src/app/data/intervenant/models/entreprise.model";
import { NavigatorExtended } from "src/app/shared/interfaces/navigator-extended.interface";
import { ReferencesService } from "src/app/data/intervenant/services/references/references.service";
import { References } from "src/app/data/intervenant/models/references.model";
import { Terroir } from "src/app/data/intervenant/models/terroir.model";
import { SuivisService } from "src/app/data/suivis/services/suivis.service";
import { DeclarationsService } from "src/app/data/declaration/services/declarations/declarations.service";
import { EmailsHelper } from "src/app/shared/helpers/emails.helper";
import { Email } from "src/app/shared/interfaces/email.interface";
import { remove } from "lodash";
import { UniteCulturaleExport } from "src/app/data/declaration/models/unite-culturale-export.model";
import {
  SortableDirective,
  SortEvent,
} from "src/app/shared/directives/sortable/sortable.directive";
import { SyntheseInventaireEdition } from "src/app/data/edition/models/synthese-inventaire-edition";
import { SyntheseInventaireUniteEdition } from "src/app/data/edition/models/synthese-inventaire-unite-edition";
import { SyntheseInventaireEspeceEdition } from "src/app/data/edition/models/synthese-inventaire-espece-edition";
import { Fichier } from "src/app/data/edition/models/fichier.model";
import { FileDownloadTools } from "src/app/shared/file-download-tools/file-download-tools";
import { SyntheseInventaireGroupeeEdition } from "src/app/data/edition/models/synthese-inventaire-groupee-edition";
import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";
import { EntrepriseBase } from "src/app/data/intervenant/models/entreprise-base.model";
import { AuthService } from "src/app/core/services/auth/auth.service";

const compare = (v1: string | number, v2: string | number) =>
  !v1 && v2 ? -1 : v1 && !v2 ? 1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: "app-suivi-inventaires-vergers",
  templateUrl: "./suivi-inventaires-vergers.component.html",
  styleUrls: ["./suivi-inventaires-vergers.component.scss"],
})
export class SuiviInventairesVergersComponent implements OnInit {
  estGP = false;
  estCT = false;
  //

  inventaireForm: FormGroup;
  suivis: SuiviInventaireVergerModel[];
  suivisFilter: SuiviInventaireVergerModel[] = [];
  filtrer = true;
  sousTypesEntreprise: Structure[];
  acteurs: Utilisateur[];
  cts: Utilisateur[];

  pourcentValides = 0;
  pourcentMisAJour = 0;
  totalNbEntreprises = 0;
  totalSurfacePomme = 0;
  totalSurfacePoire = 0;

  exportData: {};

  raisonSocialeFilter: string;
  surfacePommeFilter: number;
  surfacePoireFilter: number;
  sousTypeFilter: RefSoustypeUtilisateur;
  dateMajFilter: Date;
  dateValidationFilter: Date;
  acteurFilter: string;
  estadherentFilter: Boolean;
  terroirFilter: Terroir;
  departementFilter: string;
  villeFilter: string;
  estValideFilter: boolean;
  ctFilter: string;
  campagne: Campagne;

  entrepriseId: any;
  // Propriétés privées
  private entreprises: Array<Entreprise> = [];
  private entreprisesReferent: Array<EntrepriseReferent> = [];
  public references: References;
  public entreprisesSelectionnees: Array<number> = [];

  public listGroupement: EntrepriseBase[];
  public refSousTypes: RefSoustypeUtilisateur[];

  headers = [
    { name: "anpp", label: "adhérent ANPP", checked: false },
    { name: "terroir", label: "Terroir", checked: false },

    { name: "departement", label: "Département", checked: false },
    { name: "ville", label: "Ville", checked: false },

    { name: "valide", label: "Inventaire validé", checked: false },
    { name: "ct", label: "CT sélectionné", checked: false },
  ];

  @ViewChildren(SortableDirective) headersSort: QueryList<SortableDirective>;

  static routes: Routes = [
    {
      path: "",
      component: SuiviInventairesVergersComponent,
      data: {
        role: "ORGANISME || GP",
        domaines: ["ANPP"],
        menu: {
          libelle: "menu.suivi-inventaire-verger",
          icon: "cogs",
        },
      },
    },
    {
      path: "../../admin/params/entreprises/fiche/:id",
      component: FicheEntrepriseComponent,
      data: { roles: ["ORGANISME"] },
    },
  ];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private fb: FormBuilder,
    private suiviInventaireService: SuiviInventaireVergerService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly modalService: NgbModal,
    private readonly refSousTypeService: RefSousTypesService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly uniteCulturaleService: UniteCulturaleService,
    private readonly campagneService: RefCampagnesService,
    private readonly utilisateursService: UtilisateursService,
    private entrepriseService: EntreprisesService,
    private readonly router: Router,
    private referencesService: ReferencesService,
    private suivisService: SuivisService,
    private readonly declarationService: DeclarationsService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.suivisService.setActiveTabId("vergers");
    this.loadDataRef();
    this.loadData();
    this.inventaireForm = this.fb.group({});
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headersSort.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    if (direction === "" || column === "") {
      this.suivisFilter = this.suivis;
      this.filter();
    } else {
      this.suivisFilter = [...this.suivisFilter].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  loadData() {
    forkJoin([
      this.utilisateursService.getUtilisateur(
        SessionContext.get("idIntervenant")
      ),
      //this.entrepriseService.getEntreprises(),
      this.entrepriseService.getEntreprisesBySousRefType([
        "PO_DEFAUT",
        "PI_DEFAUT",
      ]),
      this.suiviInventaireService.getSuivisInventairesVergers(
        this.entrepriseId
      ),
    ])
      .pipe(this.loaderService.operator())
      .subscribe(([utilisateur, entreprises, suivis]) => {
        if (!utilisateur || (utilisateur && !utilisateur.entreprise)) {
          this.entrepriseId = null;
        } else {
          this.entrepriseId = utilisateur.entreprise.id;
        }
        if (utilisateur) {
          this.estGP = utilisateur.type.code === "GP";
          this.estCT = false; // TODO
        }
        this.entreprises = entreprises;
        //
        this.loadSuivi(this.entreprises, suivis);
      });
  }

  loadDataRef() {
    //this.entrepriseService.getEntreprisesReferents().subscribe(
    this.refSousTypeService
      .getRefSousType()
      .pipe(this.loaderService.operator())
      .subscribe((sousTypes: Array<RefSoustypeUtilisateur>) => {
        this.refSousTypes = sousTypes;
        this.entrepriseService
          .getEntreprisesByIdRefSousType(
            this.refSousTypes.find((r) => r.code === "GP_DEFAUT").id
          )
          .pipe(this.loaderService.operator())
          .subscribe((entreprises: Array<EntrepriseBase>) => {
            this.listGroupement = entreprises;
          });
      });

    this.entrepriseService
      .getEntreprisesReferentsPourSuiviInventaire()
      .subscribe(
        (entreprises: Array<EntrepriseReferent>) =>
          (this.entreprisesReferent = entreprises)
      );
    this.refSousTypeService.getRefSousType().subscribe((response) => {
      this.sousTypesEntreprise = response;
    });
    this.campagneService.getCampagneEnCours().subscribe((campagne) => {
      this.campagne = campagne;
    });
    this.referencesService.getReferences().subscribe((references) => {
      this.references = references;
    });
  }

  selectGroupement(idGroupement: number) {
    if (!idGroupement) {
      this.suiviInventaireService
        .getSuivisInventairesVergers(this.entrepriseId)
        .pipe(this.loaderService.operator())
        .subscribe((suivis) => {
          this.loadSuivi(this.entreprises, suivis);
        });
    } else {
      this.entrepriseService
        .getEntreprisesByIdEntrepriseLiee(idGroupement)
        .pipe(this.loaderService.operator())
        .subscribe((entreprises) => {
          this.suiviInventaireService
            .getSuivisInventairesVergers(idGroupement)
            .pipe(this.loaderService.operator())
            .subscribe((suivis) => {
              this.loadSuivi(entreprises, suivis);
            });
        });
    }
  }

  public isAnpp(): boolean {
    return this.authService.isAnpp();
  }

  loadSuivi(
    entreprises: Array<Entreprise>,
    suivis: SuiviInventaireVergerModel[]
  ) {
    this.suivis = [];
    entreprises
      .filter(
        (e) => ["PI_DEFAUT", "PO_DEFAUT"].indexOf(e.refSousType.code) > -1
      )
      .filter(
        (e) =>
          !this.estGP ||
          (e.entrepriseLiee && e.entrepriseLiee.id == this.entrepriseId)
      )
      .forEach((e) => {
        let ligneSuivi = new SuiviInventaireVergerModel();
        ligneSuivi.entreprise = e;
        ligneSuivi.idEntreprise = e.id;

        let theSuivi = suivis.find((rs) => rs.idEntreprise === e.id);
        if (theSuivi) {
          ligneSuivi.idDeclaration = theSuivi.idDeclaration;
          ligneSuivi.idEntreprise = theSuivi.idEntreprise;
          ligneSuivi.dateDerniereMaj = theSuivi.dateDerniereMaj;
          ligneSuivi.dateDerniereValidation = theSuivi.dateDerniereValidation;
          ligneSuivi.idAuteurDerniereValidation =
            theSuivi.idAuteurDerniereValidation;
          ligneSuivi.auteurModification = theSuivi.auteurModification;
          ligneSuivi.surfacePomme = theSuivi.surfacePomme;
          ligneSuivi.surfacePoire = theSuivi.surfacePoire;
          ligneSuivi.estAdherentAnpp = theSuivi.estAdherentAnpp;
          //terroir: String;
          ligneSuivi.ct = theSuivi.ct;
          //rs.typeEntreprise: String;
        }

        this.suivis.push(ligneSuivi);
      });

    this.suivisFilter = this.suivis;
    this.calculerPourcentages();
    // Uniquement pour un administrateur : nécessite un droit sur le GET user pour récupérer les noms/prénoms des intervenants
    if (this.isAnpp()) {
      this.getUsersDerniereModif();
    }
    

    // remplissage des listes pour les combos de filtre
    this.acteurs = suivis.map((s) => s.auteurModification);
    this.cts = suivis.map((s) => s.ct);

    //
    this.filter();
  }

  /**
   * modale pour sélection des colonnes
   */
  afficherListe() {
    const modalRef = this.modalService.open(ChoixColonnesComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe((headers) => {
      this.headers = headers;
    });
  }

  /**
   *
   * @param obj affichage des colonnes optionnelles
   * @returns
   */
  show(obj) {
    return this.headers.find((h) => h["name"] === obj).checked;
  }

  showFilter() {
    this.filtrer = !this.filtrer;
  }

  /**
   * exporter tous les inventaires (fitrés)
   */
  exporterInventaires() {
    let entreprises = this.suivisFilter
      .filter((s) => s.estCochee)
      .map((s) => s.idEntreprise);
    if (!entreprises || entreprises.length < 1) {
      this.modalsService
        .error(
          this.translate.instant(
            `page.inventaire-verger.modals.exporterInventaire.exportErrorSansEntreprise`
          )
        )
        .then(() => {});
    } else {
      this.uniteCulturaleService
        .exporterInventaireVerger(entreprises, this.campagne.id, false)
        .pipe(this.loaderService.operator())
        .subscribe((unites) => {
          if (unites.length > 0) {
            unites = unites.sort((a, b) =>
              a.numBlocFruitier > b.numBlocFruitier ? 1 : -1
            );
            this.exporterInventaireVerger(unites);
          } else {
            this.modalsService
              .error(
                this.translate.instant(
                  `page.inventaire-verger.modals.exporterInventaire.exportError`
                )
              )
              .then(() => {});
          }
        });
    }
  }

  exporterSynthesePdf() {
    let entreprises = this.suivisFilter
      .filter((s) => s.estCochee)
      .map((s) => s.idEntreprise);
    if (!entreprises || entreprises.length < 1) {
      this.modalsService
        .error(
          this.translate.instant(
            `page.inventaire-verger.modals.exporterInventaire.exportErrorSansEntreprise`
          )
        )
        .then(() => {});
    } else {
      this.uniteCulturaleService
        .exporterInventaireVerger(entreprises, this.campagne.id, false)
        .pipe(this.loaderService.operator())
        .subscribe((unites) => {
          if (unites.length > 0) {
            unites = unites.sort((a, b) =>
              a.numBlocFruitier > b.numBlocFruitier ? 1 : -1
            );
            const synthese: SyntheseInventaireGroupeeEdition =
              this.genererSynthesePdf(unites);
            this.uniteCulturaleService
              .exporterInventaireVergerGroupePdf(
                SessionContext.get("idOrganisme"),
                synthese
              )
              .pipe(this.loaderService.operator())
              .subscribe((fichier: Fichier): void => {
                const fileURL = URL.createObjectURL(
                  FileDownloadTools.b64ToBlob(fichier.base64, "application/pdf")
                );
                window.open(fileURL);
              });
          } else {
            this.modalsService
              .error(
                this.translate.instant(
                  `page.inventaire-verger.modals.exporterInventaire.exportError`
                )
              )
              .then(() => {});
          }
        });
    }
  }

  /**
   * valider tous les inventaires (fitrés)
   */
  validerInventaires() {
    this.modalsService
      .confirm(
        this.translate.instant(
          "page.suivi.suivi-inventaire.validation-inventaire-message"
        ),
        this.translate.instant(
          `page.suivi.suivi-inventaire.validation-inventaire-title`
        ),
        {
          cancelText: this.translate.instant(`label.annuler`),
          submitText: this.translate.instant(`label.confirmer`),
          windowClass: "anpp",
        }
      )
      .then(
        () => {
          // on ne valide que les entreprises qui ont déjà une déclaration
          let entreprises = this.suivisFilter
            .filter((s) => s.idDeclaration && s.estCochee)
            .map((s) => s.idEntreprise);
          if (entreprises.length === 0) {
            this.toastr.info("Pas d'inventaires à valider");
          } else {
            this.uniteCulturaleService
              .validerInventaireVergerList(entreprises)
              .pipe(this.loaderService.operator())
              .subscribe(() => {
                this.toastr.success("Inventaires validés");
                this.loadData();
              });
            of().subscribe(() => {
              this.toastr.success("Inventaires validés");
            });
          }
        },
        () => {}
      );
  }

  public onCheckAllDeclarations(event: any): void {
    this.entreprisesSelectionnees = [];
    this.suivisFilter.forEach((declaration: SuiviInventaireVergerModel) => {
      declaration.estCochee = event.target.checked;
      if (
        event.target.checked &&
        !this.entreprisesSelectionnees.includes(declaration.idEntreprise)
      ) {
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
      }
    });
  }

  public onCheckDeclaration(declaration: SuiviInventaireVergerModel): void {
    declaration.estCochee = !declaration.estCochee;
    this.entreprisesSelectionnees.includes(declaration.idEntreprise)
      ? remove(this.entreprisesSelectionnees, declaration.idEntreprise)
      : this.entreprisesSelectionnees.push(declaration.idEntreprise);
  }

  /**
   * mailto aux adresses des contacts référents
   */
  public relancerInventaires(): void {
    let referentsEmails: Array<string> = [];
    this.entreprisesSelectionnees.forEach(
      (idEntreprise: number) =>
        (referentsEmails = referentsEmails.concat(
          this.getReferentsEmails(idEntreprise)
        ))
    );
    referentsEmails = referentsEmails.filter((e) => e);
    if (referentsEmails && referentsEmails.length > 0) {
      EmailsHelper.envoyerEMail(<Email>{
        cci: referentsEmails.filter((e) => e),
      });
    } else {
      this.toastr.warning(
        this.translate.instant(
          "page.suivi.suivi-inventaire.emails-copies-erreur"
        ),
        this.translate.instant(
          "page.suivi.suivi-inventaire.emails-copies-titre"
        ),
        { timeOut: 10 * 1000 }
      );
    }
  }

  /**
   * copie dans le presse papier des emails des contacts référents de la ligne sélectionnée
   * @param suivi
   */
  getEmails(suivi: SuiviInventaireVergerModel) {
    const referentsEmails = this.getReferentsEmails(suivi.idEntreprise);
    if (referentsEmails != null) {
      (navigator as NavigatorExtended).clipboard
        .writeText(referentsEmails.join(";"))
        .then(() => {
          this.toastr.success(
            this.translate.instant(
              "page.suivi.suivi-inventaire.emails-copies-message"
            ),
            this.translate.instant(
              "page.suivi.suivi-inventaire.emails-copies-titre"
            ),
            { timeOut: 10 * 1000 }
          );
        })
        .catch((e) => console.error(e));
    } else {
      this.toastr.warning(
        this.translate.instant(
          "page.suivi.suivi-inventaire.emails-copies-erreur"
        ),
        this.translate.instant(
          "page.suivi.suivi-inventaire.emails-copies-titre"
        ),
        { timeOut: 10 * 1000 }
      );
    }
  }

  /**
   * accès à la fiche entreprise de la ligne sélectionnée
   * @param id
   */
  ficheEntreprise(id: Number) {
    this.router.navigate(["/main/admin/params/entreprises/fiche", id]);
  }

  /**
   * accès à l'inventaire vergers de la ligne sélectionnée
   * @param suivi
   */
  inventaireVerger(suivi: SuiviInventaireVergerModel) {
    //this.router.navigate(['/main/declarations-pp/parcellaire', suivi.idEntreprise]);
    let gp: Number = null;
    this.entrepriseService
      .getEntreprise(suivi.idEntreprise)
      .subscribe((entrepriseSelectionnee) => {
        if (entrepriseSelectionnee.refSousType.code === "PO_DEFAUT") {
          this.entrepriseService
            .getEntreprise(entrepriseSelectionnee.entrepriseLiee.id)
            .subscribe(
              (groupement) => {
                gp = groupement.id;
                this.router.navigate(["/main/declarations-pp/parcellaire"], {
                  queryParams: { id: suivi.idEntreprise, gp: gp },
                });
              },
              (error) => {}
            );
        } else {
          this.declarationService.entrepriseSelectionnee =
            entrepriseSelectionnee;
          this.router.navigate(["/main/declarations-pp/parcellaire"], {
            queryParams: { id: suivi.idEntreprise },
          });
        }
      });
  }

  filter() {
    if (this.suivis && this.suivis.length) {
      this.suivisFilter = this.suivis.filter((item) => {
        if (
          this.raisonSocialeFilter &&
          item.entreprise &&
          item.entreprise.raisonSociale
            .toLowerCase()
            .indexOf(this.raisonSocialeFilter.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          this.surfacePommeFilter &&
          !(
            Number(+item.surfacePomme).toFixed(2) ===
            Number(+this.surfacePommeFilter).toFixed(2)
          )
        ) {
          return false;
        }
        if (
          this.surfacePoireFilter &&
          !(
            Number(+item.surfacePoire).toFixed(2) ===
            Number(+this.surfacePoireFilter).toFixed(2)
          )
        ) {
          return false;
        }

        if (
          this.sousTypeFilter &&
          !(item.entreprise.refSousType.id === this.sousTypeFilter.id)
        ) {
          return false;
        }

        if (
          this.dateMajFilter &&
          (!item.dateDerniereMaj ||
            this.datePart(item.dateDerniereMaj) !=
              this.datePart(this.dateMajFilter))
        ) {
          return false;
        }

        if (
          this.dateValidationFilter &&
          (!item.dateDerniereValidation ||
            this.datePart(item.dateDerniereValidation) !=
              this.datePart(this.dateValidationFilter))
        ) {
          return false;
        }

        if (
          this.estadherentFilter !== undefined &&
          !(
            item.entreprise &&
            this.estadherentFilter === item.entreprise.estAdherent
          )
        ) {
          return false;
        }

        if (
          this.terroirFilter &&
          this.terroirFilter.departements.indexOf(
            item.entreprise.departement
          ) === -1
        ) {
          return false;
        }

        if (
          this.departementFilter &&
          item.entreprise &&
          item.entreprise.departement
            .toLowerCase()
            .indexOf(this.departementFilter.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          this.villeFilter &&
          item.entreprise &&
          item.entreprise.commune
            .toLowerCase()
            .indexOf(this.villeFilter.toLowerCase()) === -1
        ) {
          return false;
        }

        if (
          (this.acteurFilter && !item.auteurModification) ||
          (this.acteurFilter &&
            !item.auteurModification.nom
              .toLowerCase()
              .startsWith(this.acteurFilter.toLowerCase()) &&
            this.acteurFilter &&
            !item.auteurModification.prenom
              .toLowerCase()
              .startsWith(this.acteurFilter.toLowerCase()))
        ) {
          return false;
        }

        if (
          this.estValideFilter !== undefined &&
          this.estValideFilter &&
          !item.dateDerniereValidation
        ) {
          return false;
        }
        if (
          this.estValideFilter !== undefined &&
          !this.estValideFilter &&
          item.dateDerniereValidation
        ) {
          return false;
        }
        return true;
      });
    }
    this.calculerPourcentages();
  }

  //Export CSV des suivis filtrés
  exporterSuivis(): void {
    const data = [];
    const headersCSV = [];

    this.headers
      .filter((head) => head.checked)
      .forEach((header) => {
        headersCSV.push(header["libelle"]);
      });

    this.suivisFilter.forEach((suivi) => {
      this.exportData = {};
      this.exportData["groupement"] = suivi.entreprise.entrepriseLiee
        ? suivi.entreprise.entrepriseLiee.raisonSociale
        : "";
      this.exportData["raison sociale"] = suivi.entreprise.raisonSociale;
      this.exportData["surface pomme"] = suivi.surfacePomme
        ? suivi.surfacePomme
        : "";
      this.exportData["surface poire"] = suivi.surfacePoire
        ? suivi.surfacePoire
        : "";
      this.exportData["type entreprise"] = suivi.entreprise.refSousType.libelle;
      this.exportData["mise a jour"] = suivi.dateDerniereMaj
        ? this.datePart(suivi.dateDerniereMaj)
        : "";
      this.exportData["validation"] = suivi.dateDerniereValidation
        ? this.datePart(suivi.dateDerniereValidation)
        : "";
      this.exportData["acteur"] = suivi.auteurModification
        ? suivi.auteurModification.nom + " " + suivi.auteurModification.prenom
        : "";
      if (this.show("anpp"))
        this.exportData["adherent anpp"] =
          suivi.entreprise && suivi.entreprise.estAdherent ? "OUI" : "NON";
      if (this.show("terroir"))
        this.exportData["terroir"] = this.getTerroir(suivi);
      if (this.show("departement"))
        this.exportData["departement"] = suivi.entreprise.departement;
      if (this.show("ville"))
        this.exportData["ville"] = suivi.entreprise.commune;
      if (this.show("valide"))
        this.exportData["inventaire valide"] =
          suivi.dateDerniereValidation != undefined ? "OUI" : "NON";
      if (this.show("ct")) this.exportData["CT"] = suivi.ct ? suivi.ct : "";

      data.push(this.exportData);
    });

    const options = {
      filename: "EXPORT_SUIVI_INVENTAIRE"
        .concat("_")
        .concat(moment().format("YYYYMMDD")),
      fieldSeparator: ";",
      quoteStrings: "",
      decimalSeparator: ",",
      showLabels: true,
      showTitle: false,
      title: "EXPORT_SUIVI_INVENTAIRE"
        .concat("_")
        .concat(moment().format("YYYYMMDD")),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: headersCSV,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  calculerPourcentages() {
    this.totalNbEntreprises = this.suivisFilter.length;
    if (this.suivisFilter && this.suivisFilter.length > 0) {
      this.pourcentMisAJour =
        (this.suivisFilter.filter((s) => s.dateDerniereMaj).length /
          this.totalNbEntreprises) *
        100;
      this.pourcentValides =
        (this.suivisFilter.filter((s) => s.dateDerniereValidation).length /
          this.totalNbEntreprises) *
        100;

      this.totalSurfacePomme = this.suivisFilter
        .map((s) => s.surfacePomme)
        .reduce(function (a, b) {
          return (a ? a : 0) + (b ? b : 0);
        });
      this.totalSurfacePoire = this.suivisFilter
        .map((s) => s.surfacePoire)
        .reduce(function (a, b) {
          return (a ? a : 0) + (b ? b : 0);
        });
    } else {
      this.pourcentValides = undefined;
      this.pourcentMisAJour = undefined;
      this.totalSurfacePomme = undefined;
      this.totalSurfacePoire = undefined;
    }
  }

  datePart(date: Date) {
    let d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d.toLocaleDateString("fr");
  }

  exporterInventaireVerger(unites: UniteCulturaleExport[]): void {
    const data = [];
    unites.forEach((uniteCulturale) => {
      data.push({
        raisonSocialeGp: uniteCulturale.raisonSocialeGp
          ? uniteCulturale.raisonSocialeGp
          : "",
        raisonSociale: uniteCulturale.raisonSociale
          ? uniteCulturale.raisonSociale
          : "",
        numBloc: uniteCulturale.numBlocFruitier
          ? uniteCulturale.numBlocFruitier
          : "",
        codeParcelle: uniteCulturale.codeParcelle
          ? uniteCulturale.codeParcelle
          : "",
        refCadastrale: uniteCulturale.refCadastrale
          ? uniteCulturale.refCadastrale
          : "",
        numTracabilite: uniteCulturale.numTracabilite
          ? uniteCulturale.numTracabilite
          : "",
        lieuDit: uniteCulturale.lieuDit ? uniteCulturale.lieuDit : "",
        commune: uniteCulturale.commune,
        espece: uniteCulturale.espece,
        variete: uniteCulturale.variete,
        clone: uniteCulturale.clone ? uniteCulturale.clone : "",
        porteGreffe: uniteCulturale.porteGreffe
          ? uniteCulturale.porteGreffe
          : "",
        surfaceCalcule: uniteCulturale.surfaceCalculee,
        anneeSurgreffage: uniteCulturale.anneeSurgreffage
          ? uniteCulturale.anneeSurgreffage
          : "",
        anneePlantation: uniteCulturale.anneePlantation
          ? uniteCulturale.anneePlantation
          : "",
        anneePremiereFeuille: uniteCulturale.anneePremiereFeuille
          ? uniteCulturale.anneePremiereFeuille
          : "",
        cultureBio: uniteCulturale.cultureBio ? uniteCulturale.cultureBio : "",
        departement: uniteCulturale.departement,
        forme: uniteCulturale.forme,
        distanceInterRang: uniteCulturale.distanceInterRang,
        distanceIntraRang: uniteCulturale.distanceIntraRang,
        nbArbre: uniteCulturale.nbArbre,
        systemeIrrigation: uniteCulturale.systemeIrrigation
          ? uniteCulturale.systemeIrrigation
          : "",
        protectionGel: uniteCulturale.protectionGel
          ? uniteCulturale.protectionGel
          : "",
        protectionGrele: uniteCulturale.protectionGrele
          ? uniteCulturale.protectionGrele
          : "",
        situation: uniteCulturale.situation ? uniteCulturale.situation : "",
        confusion: uniteCulturale.confusion ? "OUI" : "NON",
      });
    });

    const options = {
      filename: "EXPORT_INVENTAIRE"
        .concat("_")
        .concat(moment().format("YYYYMMDD")),
      fieldSeparator: ";",
      quoteStrings: "",
      decimalSeparator: ",",
      showLabels: true,
      showTitle: false,
      title: "EXPORT_INVENTAIRE"
        .concat("_")
        .concat(moment().format("YYYYMMDD")),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        "Groupement",
        "Raison sociale",
        "Num Bloc Fruitier",
        "Code Parcelle",
        "Ref Cadastrale",
        "Num Tracabilite",
        "Lieu Dit",
        "Commune",
        "Espece",
        "Variete",
        "Clone",
        "Porte Greffe",
        "Surface Calcule",
        "Annee Surgreffage",
        "Annee Plantation",
        "Annee Premiere Feuille",
        "Culture Bio",
        "Departement",
        "Forme",
        "Distance Inter Rang",
        "Distance Intra Rang",
        "Nb Arbre",
        "Systeme Irrigation",
        "Protection Gel",
        "Protection Grele",
        "Situation",
        "Confusion",
      ],
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  genererSynthesePdf(
    unites: UniteCulturaleExport[]
  ): SyntheseInventaireGroupeeEdition {
    let syntheseInventaireGroupeeEdition =
      new SyntheseInventaireGroupeeEdition();
    let syntheseInventaireEditionList = [];

    syntheseInventaireGroupeeEdition.surfaceEspece = new Map<string, number>();
    syntheseInventaireGroupeeEdition.surfaceTotale = 0;
    syntheseInventaireGroupeeEdition.surfaceBio = 0;
    syntheseInventaireGroupeeEdition.surfaceGel = 0;
    syntheseInventaireGroupeeEdition.surfaceGrele = 0;
    syntheseInventaireGroupeeEdition.surfaceIrriguee = 0;

    unites.forEach((unite) => {
      let ligneSyntheseATraitee = syntheseInventaireEditionList.find(
        (ligne) => ligne.idEntreprise === unite.idEntreprise
      );
      if (!ligneSyntheseATraitee) {
        ligneSyntheseATraitee = new SyntheseInventaireEdition();
        const entreprise: Entreprise = this.entreprises.find(
          (ent) => ent.id == unite.idEntreprise
        );
        if (entreprise) {
          ligneSyntheseATraitee.campagne = this.campagne.libelle;
          ligneSyntheseATraitee.idEntreprise = unite.idEntreprise;
          ligneSyntheseATraitee.raisonExploitation = entreprise.raisonSociale;
          ligneSyntheseATraitee.raisonGp = entreprise.entrepriseLiee
            ? entreprise.entrepriseLiee.raisonSociale
            : entreprise.raisonSociale;
          //syntheseInventaireEdition.dateInventaire = this.datepipe.transform(this.dateLastMaj, 'dd/MM/yyyy');
          ligneSyntheseATraitee.adresse = entreprise.adresse;
          ligneSyntheseATraitee.commune =
            entreprise.codePostal + " " + entreprise.commune;
          ligneSyntheseATraitee.siret = entreprise.siret;
          ligneSyntheseATraitee.telephone = entreprise.telephone;
          ligneSyntheseATraitee.mail = entreprise.mail;
          ligneSyntheseATraitee.tva = entreprise.numeroTVA;
          ligneSyntheseATraitee.estEcoResp = entreprise.estEngageeVer;
          ligneSyntheseATraitee.estBio = entreprise.cultureBio;
          ligneSyntheseATraitee.syntheseInventaireEspeceEditionList = [];
          ligneSyntheseATraitee.surfaceTotale = 0;
          ligneSyntheseATraitee.surfaceIrriguee = 0;
          ligneSyntheseATraitee.surfaceGel = 0;
          ligneSyntheseATraitee.surfaceBio = 0;
          ligneSyntheseATraitee.surfaceGrele = 0;
          syntheseInventaireEditionList.push(ligneSyntheseATraitee);
        }
      }
      let especeExistante =
        ligneSyntheseATraitee.syntheseInventaireEspeceEditionList.find(
          (espece) => espece.libelleEspece === unite.espece
        );
      let uniteAcreer: SyntheseInventaireUniteEdition =
        new SyntheseInventaireUniteEdition();
      uniteAcreer.numBlocFruitier = unite.numBlocFruitier;
      uniteAcreer.codeParcelle = unite.codeParcelle;
      uniteAcreer.lieuDit = unite.lieuDit;
      uniteAcreer.commune = unite.commune;
      uniteAcreer.referenceCadastrale = unite.refCadastrale;
      uniteAcreer.numTracabilite = unite.numTracabilite;
      uniteAcreer.variete = unite.variete;
      uniteAcreer.clone = unite.clone ? unite.clone : null;
      uniteAcreer.porteGreffe = unite.porteGreffe;
      uniteAcreer.forme = unite.forme;
      uniteAcreer.distanceInterRang =
        Math.round(unite.distanceInterRang * 10000) / 10000;
      uniteAcreer.distanceIntraRang =
        Math.round(unite.distanceIntraRang * 10000) / 10000;
      uniteAcreer.nbArbre = unite.nbArbre;
      uniteAcreer.surfaceCalculee =
        Math.round(unite.surfaceCalculee * 10000) / 10000;
      uniteAcreer.anneePlantation = +unite.anneePlantation;
      uniteAcreer.anneePremiereFeuille = +unite.anneePremiereFeuille;
      uniteAcreer.anneeSurgreffage = +unite.anneeSurgreffage;
      uniteAcreer.refSystemeIrrigation =
        unite.systemeIrrigation &&
        unite.systemeIrrigation != "Pas d'équipement";
      uniteAcreer.refProtectionGel =
        unite.protectionGel && unite.protectionGel != "Pas d'équipement";
      uniteAcreer.refProtectionGrele =
        unite.protectionGrele && unite.protectionGrele != "Pas d'équipement";
      uniteAcreer.refCultureBio =
        unite.cultureBio && unite.cultureBio != "Non bio";
      if (especeExistante) {
        especeExistante.uniteCulturaleEditionList.push(uniteAcreer);
        especeExistante.surface =
          Math.round(
            (especeExistante.surface + uniteAcreer.surfaceCalculee) * 10000
          ) / 10000;
        if (uniteAcreer.refSystemeIrrigation == true) {
          especeExistante.surfaceIrriguee =
            Math.round(
              (especeExistante.surfaceIrriguee + uniteAcreer.surfaceCalculee) *
                10000
            ) / 10000;
        }
        if (uniteAcreer.refProtectionGel == true) {
          especeExistante.surfaceGel =
            Math.round(
              (especeExistante.surfaceGel + uniteAcreer.surfaceCalculee) * 10000
            ) / 10000;
        }
        if (uniteAcreer.refProtectionGrele == true) {
          especeExistante.surfaceGrele =
            Math.round(
              (especeExistante.surfaceGrele + uniteAcreer.surfaceCalculee) *
                10000
            ) / 10000;
        }
        if (uniteAcreer.refCultureBio == true) {
          especeExistante.surfaceBio =
            Math.round(
              (especeExistante.surfaceBio + uniteAcreer.surfaceCalculee) * 10000
            ) / 10000;
        }
        syntheseInventaireGroupeeEdition.surfaceEspece.set(
          unite.espece,
          Math.round(
            (syntheseInventaireGroupeeEdition.surfaceEspece.get(unite.espece) +
              uniteAcreer.surfaceCalculee) *
              10000
          ) / 10000
        );
      } else {
        if (!syntheseInventaireGroupeeEdition.surfaceEspece.has(unite.espece)) {
          syntheseInventaireGroupeeEdition.surfaceEspece.set(unite.espece, 0);
        }
        let especeACreee: SyntheseInventaireEspeceEdition =
          new SyntheseInventaireEspeceEdition();
        especeACreee.libelleEspece = unite.espece;
        especeACreee.uniteCulturaleEditionList = [];
        especeACreee.uniteCulturaleEditionList.push(uniteAcreer);
        especeACreee.surface =
          Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        especeACreee.surfaceIrriguee = 0;
        especeACreee.surfaceGel = 0;
        especeACreee.surfaceBio = 0;
        especeACreee.surfaceGrele = 0;
        if (uniteAcreer.refSystemeIrrigation == true) {
          especeACreee.surfaceIrriguee =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if (uniteAcreer.refProtectionGel == true) {
          especeACreee.surfaceGel =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if (uniteAcreer.refProtectionGrele == true) {
          especeACreee.surfaceGrele =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        if (uniteAcreer.refCultureBio == true) {
          especeACreee.surfaceBio =
            Math.round(uniteAcreer.surfaceCalculee * 10000) / 10000;
        }
        ligneSyntheseATraitee.syntheseInventaireEspeceEditionList.push(
          especeACreee
        );
        syntheseInventaireGroupeeEdition.surfaceEspece.set(
          unite.espece,
          syntheseInventaireGroupeeEdition.surfaceEspece.get(unite.espece) +
            uniteAcreer.surfaceCalculee
        );
      }
    });
    syntheseInventaireEditionList.forEach((element) => {
      element.syntheseInventaireEspeceEditionList.forEach((espece) => {
        element.surfaceTotale =
          Math.round((element.surfaceTotale + espece.surface) * 10000) / 10000;
        element.surfaceIrriguee =
          Math.round(
            (element.surfaceIrriguee + espece.surfaceIrriguee) * 10000
          ) / 10000;
        element.surfaceGel =
          Math.round((element.surfaceGel + espece.surfaceGel) * 10000) / 10000;
        element.surfaceBio =
          Math.round((element.surfaceBio + espece.surfaceBio) * 10000) / 10000;
        element.surfaceGrele =
          Math.round((element.surfaceGrele + espece.surfaceGrele) * 10000) /
          10000;
      });
      syntheseInventaireGroupeeEdition.surfaceTotale =
        Math.round(
          (syntheseInventaireGroupeeEdition.surfaceTotale +
            element.surfaceTotale) *
            10000
        ) / 10000;
      console.log(syntheseInventaireGroupeeEdition.surfaceTotale);

      syntheseInventaireGroupeeEdition.surfaceBio =
        Math.round(
          (syntheseInventaireGroupeeEdition.surfaceBio + element.surfaceBio) *
            10000
        ) / 10000;
      syntheseInventaireGroupeeEdition.surfaceGel =
        Math.round(
          (syntheseInventaireGroupeeEdition.surfaceGel + element.surfaceGel) *
            10000
        ) / 10000;
      syntheseInventaireGroupeeEdition.surfaceGrele =
        Math.round(
          (syntheseInventaireGroupeeEdition.surfaceGrele +
            element.surfaceGrele) *
            10000
        ) / 10000;
      syntheseInventaireGroupeeEdition.surfaceIrriguee =
        Math.round(
          (syntheseInventaireGroupeeEdition.surfaceIrriguee +
            element.surfaceIrriguee) *
            10000
        ) / 10000;
    });
    syntheseInventaireGroupeeEdition.syntheseInventaireEditionList =
      syntheseInventaireEditionList;

    return syntheseInventaireGroupeeEdition;
  }

  private getReferentsEmails(idEntreprise: number): Array<string> {
    const entreprise = this.entreprisesReferent.find(
      (entreprise: EntrepriseReferent) => entreprise.id === idEntreprise
    );

    return entreprise && entreprise.referentInventaireList.length > 0
      ? entreprise.referentInventaireList.map(
          (contact: ContactReferent) => contact.mail
        )
      : null;
  }

  getTerroir(suivi: SuiviInventaireVergerModel): String {
    let terroir = this.references.terroirs.filter(
      (t) => t.departements.indexOf(suivi.entreprise.departement) >= 0
    );
    if (terroir.length > 0) {
      return terroir[0].libelle;
    }
    return "-";
  }

  getUsersDerniereModif(): void {
    this.suivis.forEach((s) => {
      if (s.idAuteurDerniereValidation) {
        let user = this.utilisateursService
          .getUtilisateur(s.idAuteurDerniereValidation)
          .subscribe((u) => {
            s.auteurModification = u;
          });
      }
    });
  }

  selectAllEntreprises(event: any): void {
    this.filter();
  }
}
